import Vue from 'vue';
import { LiffListConfigInterface } from '@/components/list/types/list';
import orderTab from '@/modules/base/config/orderTab.ts';
import { Order, OrderItem, OrderRowExport } from '@/modules/provider/types/order';




class listConfig {
  protected vm?: Vue;
  protected listKey?: string;

  get(vm: Vue, listKey: string): LiffListConfigInterface {
    this.vm = vm;
    this.listKey = listKey;

    return {
      searchFilterOrder: 3,
      targetKey: 'order_no',
      pageTitle: 'module.order',
      search: 'order.search_keyword',
      sort: [{ label: 'data.created_at', key: 'created_at' }],
      liffFilter: {
        status: {
          type: 'tab',
          options: () => {
            return orderTab;
          },
        },
      },
      listItem: () =>
        import('@/modules/provider/views/order/orderCardItem.vue'),
      useRow: false,
      batch: {
        batch_apply: {
          label: '批次接單',
          column: 'selected',
          value: 'apply',
        },
        batch_sended: {
          label: '批次出貨',
          column: 'selected',
          value: 'sended',
        },
      },
      selectedData: row => row,
      exportExcel: {
        withoutConfirm: true,
        filename: (time: string) => `${this.vm?.$t('module.order')}`,
        getData: (result: any[]) => (
          result.reduce((completeData: OrderRowExport[], order: Order) => {
            const {
              created_at,
              store_name,
              order_no,
              receive_name,
              address,
              phone,
              comment,
              delivery_fee,
              items,
            } = order;
            const data = items.map((item: OrderItem): OrderRowExport => {
              const {
                product,
                count,
                shipping_unit,
                shipping,
                logistic_provider,
                logistic_no,
              } = item;
              return {
                created_at,
                store_name,
                order_no,
                receive_name,
                address,
                phone,
                comment,
                delivery_fee,
                items_product: product,
                items_count: count,
                items_shipping_unit: shipping_unit,
                items_shipping: shipping,
                items_logistic_provider: logistic_provider,
                items_logistic_no: logistic_no,
              };
            });
            console.log(completeData);
            completeData.push(...data);
            return completeData;
          }, [])
        ),
        sheets: [
          {
            label: 'module.order',
            data: {
              created_at: {
                label: 'data.order.created_at',
                width: 18,
              },
              store_name: {
                label: 'data.order.store_name',
                width: 18,
              },
              order_no: {
                label: 'data.order.order_no',
                width: 26,
              },
              receive_name: {
                label: 'data.order.receive_name',
                width: 26,
              },
              address: {
                label: 'data.order.address',
                width: 26,
              },
              phone: {
                label: 'data.order.phone',
                width: 26,
              },
              items_product: {
                label: 'data.order.items.product',
                width: 26,
              },
              items_count: {
                label: 'data.order.items.count',
                width: 26,
              },
              items_shipping_unit: {
                label: 'data.order.items.shipping_unit',
                width: 26,
              },
              delivery_fee: {
                label: 'data.order.items.delivery_fee',
                width: 26,
              },
              items_logistic_provider: {
                label: 'data.order.items.logistic_provider',
                width: 26,
              },
              items_logistic_no: {
                label: 'data.order.items.logistic_no',
                width: 26,
              },
              comment: {
                label: 'data.order.comment',
                width: 26,
              },
            },
          },
        ],
      },
    };
  }
}

export default new listConfig();
