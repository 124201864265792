export default [
    // 付款方式
    {
        text : "全部訂單",
        value : ""
    },
    {
        text : "新訂單",
        value : "created"
    },
    {
        text : "未出貨",
        value : "apply"
    },
    {
        text : "出貨中",
        value : "sended"
    },
    {
        text : "已簽收",
        value : "finished"
    },
    {
        text : "已拒絕",
        value : "reject"
    },
    {
        text : "退貨申請",
        value : "request_return"
    },
    {
        text : "已取消",
        value : "canceled"
    }
]